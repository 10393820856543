import { format } from "date-fns";
import { map } from "lodash";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useSearchParams } from "react-router-dom";
import HrefWrapper from "../../Components/HrefWrapper";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import { FetchListUserNamesResponse, FetchSeenSpeciesUsersResponseHaveSeen } from "../../Types/apiResponses";
import { fetchSeenSpeciesUsers } from "../../Utils/apiUtils";
import { useTableSort } from "../../Utils/generalUtils";

function ObservationsSeenPage() {
  const location = useLocation();
  const [search, setSearch] = useState("");
  const [searchParams] = useSearchParams();
  const [listToShow, setListToShow] = useState<"have_seen" | "not_seen">("have_seen");

  const seenQuery = useQuery(
    "seen",
    () => fetchSeenSpeciesUsers(location.search),
    {
      refetchOnWindowFocus: false,
    }
  );

  const [haveSeen, setHaveSeenKey] = useTableSort(
    seenQuery.data?.have_seen || []
  );

  const [haveNotSeen, setHaveNotSeenKey] = useTableSort(
    seenQuery.data?.not_seen || [],
  );

  useEffect(() => {
    if (seenQuery.data) {
      setHaveNotSeenKey("name");
    }
  }, [seenQuery.data]);

  const parseDate = (parseObject: any | undefined) => {
    const {
      date_from,
      date_to,
      interval_monthday_from,
      interval_monthday_to,
    } = parseObject || {};

    if (date_to && date_from && date_to !== date_from) {
      if (
        isNaN(new Date(date_to).getTime()) ||
        isNaN(new Date(date_from).getTime())
      ) {
        return "Virheellinen aika!";
      }

      return (
        format(new Date(date_from), "dd.MM.yyyy") +
        " - " +
        format(new Date(date_to), "dd.MM.yyyy")
      );
    }
    if (date_from) {
      return format(new Date(date_from), "dd.MM.yyyy");
    }

    if (interval_monthday_from && interval_monthday_to) {
      const interval_string_from =
        interval_monthday_from >= 1000
          ? interval_monthday_from.toString()
          : "0" + interval_monthday_from.toString();
      const interval_string_to =
        interval_monthday_to >= 1000
          ? interval_monthday_to.toString()
          : "0" + interval_monthday_to.toString();
      const formattedFrom =
        interval_string_from.slice(2, 4) +
        "." +
        interval_string_from.slice(0, 2);
      const formattedTo =
        interval_string_to.slice(2, 4) +
        "." +
        interval_string_to.slice(0, 2);
      return formattedFrom + " - " + formattedTo;
    }
    return "0.0.0000";
  };

  const parsePlace = (
    seen: FetchSeenSpeciesUsersResponseHaveSeen
  ) => {
    const { country_name, municipality_name } = seen;
    if (municipality_name) {
      return municipality_name;
    }
    if (country_name) {
      return country_name;
    }
    return "...";
  };

  const userObservationsHref = (userId: number | undefined) => {
    const speciesId = searchParams.get("speciesId");
    const listId = searchParams.get("listId");
    if (!userId || !speciesId || !listId) return "#";
    return `/havainnot?userId=${userId}&listId=${listId}&speciesId=${speciesId}`;
  };

  const filterSeen = (seen: any) => {
    if (!search) return true;
    const wholeName =
      seen.firstname.toLowerCase() +
      " " +
      seen.lastname.toLowerCase();
    return wholeName.includes(search.toLowerCase());
  };

  return (
    <>
      <div className="row w-100 m-0 p-0 justify-content-center my-4">
        <div className="col-12 col-lg-8">
          <div className="w-100 row m-0 p-0 p-2">
            <div className="w-100 m-0 p-0">
              <p className="p-0 m-0">
                <b>Lista: </b>
                {seenQuery.data?.list.name || "..."}
              </p>
            </div>
            <div className="w-100 m-0 p-0">
              <p className="p-0 m-0">
                <b>Kuvaus: </b>
                {seenQuery.data?.list.description || "..."}
              </p>
            </div>
            <div className="w-100 m-0 p-0">
              <p className="p-0 m-0">
                <b>Laji: </b>
                {seenQuery.data
                  ? `${
                      seenQuery.data?.specie.ioc_finnish || ""
                    } | ${
                      seenQuery.data?.specie.ioc_english || ""
                    } | ${
                      seenQuery.data?.specie.ioc_scientific || ""
                    }`
                  : "..."}
              </p>
            </div>
            <div className="w-100 m-0 p-0">
              <p className="p-0 m-0">
                <b>Aika: </b>
                {parseDate(seenQuery.data?.list)}
              </p>
            </div>
          </div>
          <div className="my-3">
            <input
              type="text"
              className="w-100 p-1"
              placeholder={"Hae nimeä"}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="w-100 d-flex justify-content-start gap-2">
            <button
              className={`btn btn-sm ${
                listToShow === "have_seen" ? "btn-primary" : "btn-outline-primary"
              }`}
              onClick={() => setListToShow("have_seen")}
            >
              { search ? `Nähnyt (${haveSeen.filter(filterSeen).length})` : `Nähnyt (${haveSeen.length})`}
            </button>
            <button
              className={`btn btn-sm ${
                listToShow === "not_seen" ? "btn-primary" : "btn-outline-primary"
              }`}
              onClick={() => setListToShow("not_seen")}
            >
              { search ? `Ei nähnyt (${haveNotSeen.filter(filterSeen).length})` : `Ei nähnyt (${haveNotSeen.length})`}
            </button>
          </div>
        </div>
      </div>

      <div className="row w-100 m-0 p-0 justify-content-center main-container py-3">
        <div className="col-12 col-lg-8 m-0 p-0">
          {listToShow === "have_seen" ? (
          <div className="w-100 row m-0 p-0 observations-container pb-2">
            {seenQuery.isFetching && (
              <div className="row w-100 m-0 p-0 py-2 justify-content-center">
                <LoadingSpinner />
              </div>
            )}
            {seenQuery.data && !seenQuery.isFetching && (
              <div className="m-0 py-2 p-0 table-responsive">
                <table className="table table-striped table-sm">
                  <thead>
                    <tr>
                      <th scope="col" className="px-2">
                        <b>#</b>
                      </th>
                      <th
                        scope="col"
                        className="table-column-pressable"
                        onClick={() => setHaveSeenKey("name")}
                      >
                        Nimi
                      </th>
                      <th
                        scope="col"
                        className="table-column-pressable"
                        onClick={() => setHaveSeenKey("date")}
                      >
                        Aika
                      </th>
                      <th scope="col">Paikka</th>
                      <th scope="col">Lisätiedot</th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(
                      (
                        haveSeen as FetchSeenSpeciesUsersResponseHaveSeen[]
                      ).filter(filterSeen),

                      (seen, index) => {
                        return (
                          <tr key={index}>
                            <td className="px-2">{`${
                              index + 1
                            }.`}</td>
                            <td className="table-column-pressable">
                              <HrefWrapper
                                href={userObservationsHref(
                                  seen.user_id
                                )}
                              >
                                {`${seen.firstname} ${seen.lastname}`}
                              </HrefWrapper>
                            </td>
                            <td>{`${parseDate(seen)}`}</td>
                            <td>{parsePlace(seen)}</td>
                            <td>{seen.additional_info}</td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>)
          : 
          (<div className="w-100 row m-0 p-0 observations-container pb-2">
            {seenQuery.isFetching && (
              <div className="row w-100 m-0 p-0 py-2 justify-content-center">
                <LoadingSpinner />
              </div>
            )}
            {seenQuery.data && !seenQuery.isFetching && (
              <div className="m-0 py-2 p-0 table-responsive">
                <table className="table table-striped table-sm">
                  <thead>
                    <tr>
                      <th scope="col" className="px-2">
                        <b>#</b>
                      </th>
                      <th
                        scope="col"
                        className="table-column-pressable"
                        onClick={() => setHaveNotSeenKey("name")}
                      >
                        Nimi
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(
                      (
                        haveNotSeen as FetchListUserNamesResponse[]
                      ).filter(filterSeen),

                      (not_seen, index) => {
                        return (
                          <tr key={index}>
                            <td className="px-2">{`${
                              index + 1
                            }.`}</td>
                            <td className="table-column-pressable">
                              <HrefWrapper
                                href={userObservationsHref(
                                  not_seen.id
                                )}
                              >
                                {`${not_seen.firstname} ${not_seen.lastname}`}
                              </HrefWrapper>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ObservationsSeenPage;

import clsx from "clsx";
import { format as dateFormat } from "date-fns";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CheckBoxPagination from "../../Components/CheckBoxPagination";
import CustomCheckBox from "../../Components/CustomInputs/CustomCheckBox";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import useLocalStorageValue from "../../Hooks/useLocalStorageValue";
import {
  FilteredObservationResponse,
  ListResponse,
  UserType,
} from "../../Types/apiResponses";

interface ICheckBoxEditorListProps {
  taxons: Partial<
    FilteredObservationResponse & {
      isFromObs?: boolean;
    }
  >[];
  list: ListResponse | undefined | null;
  deleteObs: (id: number) => void;
  createObs: (
    species: string,
    additional_info: string,
    date: string,
    keywords: string,
    single: Partial<FilteredObservationResponse> & {
      isFromObs?: boolean;
    }
  ) => void;
  redirectToEdit: (id: number) => void;
  seenTaxons: Partial<FilteredObservationResponse>[];
}

type TaxonLanguage =
  | "ioc_finnish"
  | "ioc_english"
  | "ioc_scientific"
  | "scientific_abbreviation";

function CheckBoxEditorList(props: ICheckBoxEditorListProps) {
  const [searchParams] = useSearchParams();
  const { taxons, deleteObs, createObs, redirectToEdit, list } =
    props;
  const [page, setPage] = useState(0);
  const [filteredTaxons, setFilteredTaxons] = useState<
    Partial<
      FilteredObservationResponse & { isFromObs?: boolean }
    >[]
  >([]);
  let language = searchParams.get("language") as TaxonLanguage;
  const [user] = useLocalStorageValue<UserType | null>(
    "user",
    null
  );

  useEffect(() => {
    if (taxons.length > 0) {
      filterTaxons(taxons);
    }
  }, [taxons, props.seenTaxons]);

  const filterTaxons = (
    taxons: Partial<
      FilteredObservationResponse & { isFromObs?: boolean }
    >[]
  ) => {
    /** filter also according to seach */
    let taxonsToSearch = taxons;
    const search = searchParams.get("search");
    if (search && search !== "" && taxonsToSearch.length > 0) {
      taxonsToSearch = taxonsToSearch.filter((taxon) => {

        if (language === "scientific_abbreviation") {

          if (taxon.scientific_abbreviation) {
            return taxon.scientific_abbreviation.some((sci: string) =>
              sci.toLowerCase().includes(search.toLowerCase())
            );
          }
        } else {
          return taxon[language]
            ?.toLowerCase()
            .includes(search.toLowerCase());
        }
      });
    }
    const seenOnList = searchParams.get("seenOnList");
    if (seenOnList === "true") {
      /** Remove all from taxonsToSearch where it does not have the same id in seenTaxons (OR IF IT HAS isFromObs true then INCLUDE IT) */
      taxonsToSearch = taxonsToSearch.filter((taxon) => {
        const found = props.seenTaxons.find(
          (seenTaxon) =>
            seenTaxon.id === taxon.id || taxon.isFromObs
        );
        return found;
      });
    }
    setFilteredTaxons(taxonsToSearch);
  };

  const paginate = () => {
    const limit = searchParams.get("limit") || "1000";
    const perPage = parseInt(limit);
    const start = page * perPage;
    const end = start + perPage;
    return filteredTaxons.slice(start, end);
  };

  const getPlace = (
    single: Partial<FilteredObservationResponse> & {
      isFromObs?: boolean;
    }
  ) => {
    if (single.municipality_name) {
      return single.municipality_name;
    }
    if (single.association_name) {
      return single.association_name;
    }
    if (single.country_name) {
      return single.country_name;
    }
    if (single.continent_name) {
      return single.continent_name;
    }
    return "";
  };

  const getTime = (
    single: Partial<FilteredObservationResponse> & {
      isFromObs?: boolean;
    }
  ) => {
    /** Try to parse date_to and date_from if they are same return date_to
     * if not return date_from - date_to
     * if neither exist return 0.0
     */
    const date_to = single.date_to
      ? new Date(single.date_to)
      : undefined;
    const date_from = single.date_from
      ? new Date(single.date_from)
      : undefined;

    const interval_from = single.interval_monthday_from;
    const interval_to = single.interval_monthday_to;

    if (date_to && date_from) {
      if (date_to.getTime() === date_from.getTime()) {
        return dateFormat(date_to, "dd.MM.yyyy");
      }
      return `${dateFormat(
        date_from,
        "dd.MM.yyyy"
      )} - ${dateFormat(date_to, "dd.MM.yyyy")}`;
    }
    if (interval_from && interval_to) {
      /** interval_from and interval_to are MMdd or Mdd number convert string and format to dd.MM before retrning */
      try {
        const interval = parseIntervals(
          interval_from,
          interval_to
        );
        return interval;
      } catch (error) {
        return "";
      }
    }
  };

  const parseIntervals = (
    interval_from: number,
    interval_to: number,
    format: "dd.MM" | "MMdd" = "dd.MM"
  ) => {
    /** needs utility function, I love padded ints as date column! */
    const interval_from_string = interval_from.toString();
    const interval_to_string = interval_to.toString();
    /** pad if they are too short */
    const interval_from_padded = interval_from_string.padStart(
      4,
      "0"
    );
    const interval_to_padded = interval_to_string.padStart(
      4,
      "0"
    );
    /** split to month and day */
    const interval_from_month = interval_from_padded.slice(0, 2);
    const interval_from_day = interval_from_padded.slice(2);
    const interval_to_month = interval_to_padded.slice(0, 2);
    const interval_to_day = interval_to_padded.slice(2);
    /** format to dd.MM */
    if (format === "dd.MM") {
      return `${interval_from_day}.${interval_from_month}-${interval_to_day}.${interval_to_month}`;
    }
    return `${interval_from_month}${interval_from_day}-${interval_to_month}${interval_to_day}`;
  };

  const changePage = (page: number) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setPage(page);
  };

  const handleSubmit = (
    single: Partial<
      FilteredObservationResponse & { isFromObs?: boolean }
    >
  ) => {
    if (single.isFromObs) return;
    const species = single.id?.toString();
    if (!species) return;
    const additional = searchParams.get("additional_info") || "";
    let date = searchParams.get("date") || "";
    const keywords = searchParams.get("keywords") || "";
    if (!date) {
      const dateFromList = list?.date_to;
      const dateToList = list?.date_from;
      const listFromInterval = parseInt(
        list?.interval_monthday_from || ""
      );
      const listToInterval = parseInt(
        list?.interval_monthday_to || ""
      );
      if (dateFromList && dateToList) {
        date = `${dateFromList}-${dateToList}`;
      } else if (listFromInterval && listToInterval) {
        try {
          date = parseIntervals(
            listFromInterval,
            listToInterval,
            "MMdd"
          );
        } catch (e) {
          date = "";
        }
      }
    }
    createObs(species, additional, date, keywords, single);
  };

  const handleDelete = (
    single: Partial<
      FilteredObservationResponse & { isFromObs?: boolean }
    >
  ) => {
    if (single.isFromObs !== true) return;
    if (!single.id) return;
    single.isFromObs = false;
    deleteObs(single.id);
  };

  if (!language) {
    language = "ioc_finnish";
  }

  if (filteredTaxons.length <= 0) {
    return (
      <div className="main-container m-0 p-0">
        <div className="row w-100 justify-content-center m-0 p-0 my-4">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className="main-container-no-border m-0 p-0">
      <div className="row w-100 justify-content-center m-0 p-0 py-4">
        <div className="col-12 col-md-10 col-lg-8" id="top">
          <CheckBoxPagination
            page={page}
            rowCount={filteredTaxons.length}
            rowsPerPage={
              parseInt(searchParams.get("limit") || "") || 0
            }
            showPagesCount={10}
            changePage={changePage}
          />
          <div className="m-0 p-0 table-responsive">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col">Nähty</th>
                  <th scope="col">Laji</th>
                  <th scope="col">Paikka</th>
                  <th scope="col">Aika</th>
                  <th scope="col">Lisätiedot</th>
                  <th scope="col">Asiasanat</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {paginate().map((taxon, index) => (
                  <tr key={`${taxon.id}-${index}`}>
                    <td>
                      <CustomCheckBox
                        checked={
                          taxon.isFromObs === true ? true : false
                        }
                        size={35}
                        icon={{
                          checked: "bi bi-check-square-fill",
                          unchecked: "bi bi-square",
                        }}
                        colors={{
                          checked: "#a07702",
                          unchecked: "#a07702",
                        }}
                        onChange={() => {
                          handleSubmit(taxon);
                        }}
                      />
                    </td>
                    <td>
                      <p
                        className={clsx("m-0 p-0", {
                          "table-column-pressable":
                            taxon.isFromObs,
                        })}
                        onClick={() => {
                          if (
                            list &&
                            list.id &&
                            user?.id &&
                            taxon.species_id &&
                            taxon.isFromObs
                          ) {
                            window.open(
                              `/havainnot?userId=${user.id}&listId=${list.id}&speciesId=${taxon.species_id}`,
                              "_blank"
                            );
                          }
                        }}
                      >
                        { language === "scientific_abbreviation" ? 
                        (
                          taxon.scientific_abbreviation && taxon.scientific_abbreviation[0] ? taxon.scientific_abbreviation.join(", ") : `${taxon.ioc_english || "[Lajin nimi puuttuu]"}`
                        )
                        :
                        taxon[language] ||
                          "[Lajin nimi puuttuu]"
                        }
                      </p>
                    </td>
                    <td>
                      <p className="m-0 p-0">
                        {getPlace(taxon)}
                      </p>
                    </td>
                    <td>
                      <p className="m-0 p-0">{getTime(taxon)}</p>
                    </td>
                    <td>
                      <p className="m-0 p-0">
                        {taxon.additional_info || ""}
                      </p>
                    </td>
                    <td>
                      <p className="m-0 p-0">
                        {taxon.keyword_names || ""}
                      </p>
                    </td>
                    <td className="text-end">
                      <div
                        className={
                          taxon.isFromObs ? "d-flex" : "d-none"
                        }
                      >
                        <i
                          onClick={() => {
                            if (
                              taxon.isFromObs === true &&
                              taxon.id
                            ) {
                              redirectToEdit(taxon.id);
                            }
                          }}
                          className="bi bi-pencil-fill checkbox-tool-icon px-2"
                        />
                        <i
                          onClick={() => {
                            handleDelete(taxon);
                          }}
                          className="bi bi-trash-fill checkbox-tool-icon px-2"
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="m-0 p-0 my-2 w-100">
        <CheckBoxPagination
          page={page}
          rowCount={filteredTaxons.length}
          rowsPerPage={
            parseInt(searchParams.get("limit") || "") || 0
          }
          showPagesCount={10}
          changePage={changePage}
        />
      </div>
    </div>
  );
}

export default CheckBoxEditorList;

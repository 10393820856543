import axios from "axios";
import { format } from "date-fns";
import { fi } from "date-fns/locale";
import { useMutation, useQuery } from "react-query";
import Swal from "sweetalert2";
import { FilteredObservationResponse } from "../Types/apiResponses";
import { parseDate } from "../Utils/generalUtils";

interface ReportButtonProps {
  observation: FilteredObservationResponse;
}

interface AlreadyFlaggedResponse {
  message: string;
  created_at: string;
}

function ReportButton(props: ReportButtonProps) {
  const { observation } = props;

  const flagMutation = useMutation(
    async (message: string) => {
      const response = await axios.post("/observations/report", {
        message,
        observation_id: observation.id,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        Swal.fire({
          title: "Havainto liputettu",
          text: "Havainto on liputettu onnistuneesti",
          icon: "success",
        });
      },
      onError: (error: any) => {
        Swal.fire({
          title: "Virhe",
          text: "Havainnon liputus epäonnistui, koita myöhemmin uudestaan",
          icon: "error",
        });
      },
    }
  );

  const checkFlagExistsQuery = useQuery(
    ["checkFlagExists", observation.id],
    async () => {
      const response = await axios.get(
        `/observations/${observation.id}/report`
      );
      return response.data;
    },
    {
      enabled: false,
      retry: false,
      onSuccess: () => {
        Swal.fire({
          title: "Liputa havainto",
          width: "800px",
          showCancelButton: true,
          confirmButtonText: "Liputa",
          cancelButtonText: "Peruuta",
          reverseButtons: true,
          html: `
          <div class="container-fluid p-3">
            <div class="list-group">
              <div class="list-group-item">
                <strong>Laji: </strong>${
                  observation.ioc_finnish
                } | ${observation.ioc_english} | ${
            observation.ioc_scientific
          } | ${observation.scientific_abbreviation.join(", ")}
              </div>
              <div class="list-group-item">
                <strong>Aika:</strong> ${parseDate(observation)}
              </div>
              <div class="list-group-item">
                <strong>Paikka:</strong> ${
                  observation.municipality_name ||
                  observation.association_name ||
                  observation.country_name ||
                  observation.continent_name ||
                  "..."
                } 
              </div>
              <div class="list-group-item">
                <strong>Lisätiedot: </strong>${
                  observation.additional_info || "..."
                }
              </div>
              <div class="list-group-item">
                <strong>Asiasanat: </strong>${
                  observation.keyword_names || "..."
                }
              </div>
            </div>
            <div class="form-group mt-3">
              <label for="messageTextarea">
                Liputuksen viesti
              </label>
              <textarea
                class="form-control"
                id="report-message-${observation.id}"
                rows="3"
                placeholder="Kirjoita viesti tähän..."></textarea>
            </div>
          </div>
        `,
          preConfirm: () => {
            const message =
              (
                document.getElementById(
                  `report-message-${observation.id}`
                ) as any
              )?.value || "";
            flagMutation.mutate(message);
          },
        });
      },
      onError: (error: any) => {
        if (error.response.status === 409) {
          const formattedDate = format(
            new Date(error.response.data.created_at),
            "d MMMM yyyy",
            { locale: fi }
          );
          Swal.fire({
            title: "Havainto on jo liputettu",
            html: `
              <div className="text-center">
                <p><strong>Päivämäärä:</strong><br/>${formattedDate}</p>
                <p><strong>Viestillä:</strong><br/>"${error.response.data.message}"</p>
              </div>
            `,
            icon: "info",
          });
        } else {
          Swal.fire({
            title: "Virhe",
            html: "Havainnon liputuksen tarkistus epäonnistui.<br/>Yritä uudelleen myöhemmin.",
            icon: "error",
          });
        }
      },
    }
  );

  return (
    <div>
      <i
        onClick={() => {
          checkFlagExistsQuery.refetch();
        }}
        className="bi bi-flag-fill icon-secondary cursor-pointer"
      ></i>
    </div>
  );
}

export default ReportButton;

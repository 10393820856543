import { useEffect, useState } from "react";
import {
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { useLocation, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import useLocalStorageValue from "../../Hooks/useLocalStorageValue";
import {
  FilteredObservationResponse,
  FilteredObservationsResponse,
} from "../../Types/apiResponses";
import {
  deleteObservation,
  fetchFilteredObservations,
} from "../../Utils/apiUtils";
import {
  getAreaFromContinent,
  parseDate,
  useTableSort,
} from "../../Utils/generalUtils";
import "./styles.scss";

import { format as dateFormat } from "date-fns";
import HrefWrapper from "../../Components/HrefWrapper";
import ReportButton from "../../Components/ReportButton";

interface UserLocal {
  id: number;
}

/**Component makes table from observationQuery.data.observations if there are any. It also has a state to order table by observation name and date */
function ObservationsPage() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [language, setLanguage] = useState<
    | "ioc_finnish"
    | "ioc_english"
    | "ioc_scientific"
    | "scientific_abbreviation"
  >("ioc_finnish");
  const [search, setSearch] = useState("");
  const queryClient = useQueryClient();
  const [user] = useLocalStorageValue<UserLocal | undefined>(
    "user",
    undefined
  );

  const observationsQuery = useQuery(
    ["observation-detail", location.search],
    () =>
      fetchFilteredObservations(
        searchParams.get("listId"),
        searchParams.get("userId"),
        searchParams.get("speciesId")
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setList(data.observations);
      },
    }
  );

  const [defaultLanguage, setDefaultLanguage] =
    useLocalStorageValue<
      | "ioc_finnish"
      | "ioc_english"
      | "ioc_scientific"
      | "scientific_abbreviation"
      | null
    >("editor-default-language", null);

  useEffect(() => {
    if (defaultLanguage) {
      setLanguage(defaultLanguage);
    }
  }, []);

  const shouldShowTools = () => {
    const userParam = searchParams.get("userId");
    const userLocal = user?.id.toString();
    if (!userParam || !userLocal) return false;
    return userLocal === userParam;
  };

  const deleteObsMutation = useMutation(
    (id: number) => deleteObservation(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "observation-detail",
          "*",
        ]);
        observationsQuery.refetch();
      },
      onError: () => {
        Swal.fire({
          icon: "error",
          title: "HUPS!",
          text: "Jokin meni pieleen havaintoa poistaessa",
        });
        queryClient.invalidateQueries([
          "observation-detail",
          "*",
        ]);
      },
      onMutate: async (id: number) => {
        await queryClient.cancelQueries([
          "observation-detail",
          location.search,
        ]);
        const previousObs =
          queryClient.getQueryData<FilteredObservationsResponse>(
            ["observation-detail", location.search]
          );
        if (previousObs) {
          queryClient.setQueryData<FilteredObservationsResponse>(
            ["observation-detail", location.search],
            {
              ...previousObs,
              observations: previousObs.observations.filter(
                (obs) => obs.id !== id
              ),
            }
          );
        }
        return { previousObs };
      },
    }
  );

  const handleDeleteObs = (id: number | undefined) => {
    if (!id) return;
    Swal.fire({
      title: "Haluatko varmasti poistaa havainnon?",
      text: "Havaintoa ei voi palauttaa!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "grey",
      confirmButtonText: "Kyllä",
      cancelButtonText: "Peruuta",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteObsMutation.mutate(id);
      }
    });
  };

  const editHref = (obs: FilteredObservationResponse) => {
    const {
      species_id,
      additional_info,
      date_to,
      date_from,
      continent_id,
      country_id,
      association_id,
      municipality_id,
      id,
      keyword_ids,
    } = obs;

    if (!id || !species_id) {
      return;
    }

    let dateToFormatted;
    if (date_from !== date_to || date_from === null) {
      dateToFormatted = "-1";
    } else if (date_to) {
      try {
        dateToFormatted = dateFormat(
          new Date(date_to),
          "yyyy-MM-dd"
        );
      } catch (error) {
        dateToFormatted = undefined;
      }
    }
    const area = getAreaFromContinent(continent_id);

    let redirectParams = `edit=${id}&species=${species_id}&area=${area}`;
    if (additional_info) {
      redirectParams += `&additional_info=${additional_info}`;
    }
    if (dateToFormatted) {
      redirectParams += `&date=${dateToFormatted}`;
    }
    if (continent_id) {
      redirectParams += `&continent=${continent_id}`;
    }
    if (country_id) {
      redirectParams += `&country=${country_id}`;
    }
    if (association_id) {
      redirectParams += `&association=${association_id}`;
    }
    if (municipality_id) {
      redirectParams += `&municipality=${municipality_id}`;
    }
    if (keyword_ids) {
      redirectParams += `&keywords=${keyword_ids}`;
    }
    const newUrl = `/havainto?${redirectParams}`;
    return newUrl;
  };

  const filterOrderBySearch = () => {
    return order.filter((obs: FilteredObservationResponse) => {

      if (obs[language]) {
        if (language === "scientific_abbreviation") {
          return obs[language].some((sci: string) =>
            sci.toLowerCase().includes(search.toLowerCase())
          );
        } else {
          return obs[language]
            .toLowerCase()
            .includes(search.toLowerCase());
        }
      }

      return false;
    });
  };

  const isMissingAbbreviation = () => {

    let missingAbbreviation = false;

    ( 
      filterOrderBySearch() as FilteredObservationResponse[]
    ).forEach((taxon) => {
      if (taxon.scientific_abbreviation === undefined || taxon.scientific_abbreviation[0] === "") {
        missingAbbreviation = true;
      }
    });

    return missingAbbreviation;
  };

  const [order, setOrderKey, setList] = useTableSort(
    observationsQuery.data?.observations || []
  );

  useEffect(() => {
    observationsQuery.refetch();
  }, [location.search]);

  const getPlace = (observation: any) => {
    if (observation.municipality_name)
      return observation.municipality_name;
    if (observation.association_name)
      return observation.association_name;
    if (observation.country_name)
      return observation.country_name;
    return "";
  };

  const changeLanguage = (
    language:
      | "ioc_finnish"
      | "ioc_english"
      | "ioc_scientific"
      | "scientific_abbreviation"
  ) => {
    setLanguage(language);
    setDefaultLanguage(language);
  };

  const getUserObservationsHref = (
    userId: number | undefined,
    speciesId: number | undefined,
    listId: number | undefined
  ) => {
    if (!userId || !speciesId) return "#";
    return `/havainnot?userId=${userId}&listId=${listId}&speciesId=${speciesId}`;
  };

  const hasContent = () => {
    return (
      observationsQuery.data?.observations &&
      observationsQuery.data?.observations.length > 0 &&
      observationsQuery.isSuccess
    );
  };

  const navPosition = () => {
    const userId = searchParams.get("userId");
    const listId = searchParams.get("listId");
    const speciesId = searchParams.get("speciesId");

    if (userId && listId && speciesId) {
      return "All";
    }
    if (userId && listId) {
      return "User";
    }
    return "NotReady";
  };

  return (
    <>
      <div className="row w-100 m-0 p-0 justify-content-center my-2">
        <div className="col-12 col-lg-8">
          <div className="w-100 row m-0 p-0 p-2">
            <div className="w-100">
              <p className="p-0 m-0">
                <b>Lista: </b>
                {observationsQuery.data?.list?.name || "..."}
              </p>
            </div>
            <div className="w-100">
              <p className="p-0 m-0">
                <b>Kuvaus: </b>
                {observationsQuery.data?.list?.description ||
                  "..."}
              </p>
            </div>
            <div className="w-100">
              <p className="p-0 m-0">
                <b>Käyttäjä: </b>
                {observationsQuery.data?.user?.firstname &&
                observationsQuery.data?.user.lastname
                  ? `${observationsQuery.data?.user?.firstname} ${observationsQuery.data?.user?.lastname}`
                  : "..."}
              </p>
            </div>
            <div className="w-100">
              <p className="p-0 m-0">
                <b>Laji: </b>
                {observationsQuery.data?.species?.ioc_finnish ||
                  "..."}
              </p>
            </div>

            <div className="w-100">
              <p className="p-0 m-0">
                <b>Aika: </b>
                {parseDate(observationsQuery.data?.list as any)}
              </p>
            </div>
          </div>
          <div className="w-100 text-center gap-2 row justify-content-start m-0 p-0 px-2 pt-1 pb-3">
            <div
              className="col-1 p-0 language-button rounded cursor-pointer"
              onClick={() => changeLanguage("ioc_finnish")}
              style={{
                backgroundColor:
                  language === "ioc_finnish"
                    ? "#ffbe00"
                    : "#a07702",
              }}
            >
              FI
            </div>
            <div
              className="col-1 p-0 language-button rounded cursor-pointer"
              onClick={() => changeLanguage("ioc_english")}
              style={{
                backgroundColor:
                  language === "ioc_english"
                    ? "#ffbe00"
                    : "#a07702",
              }}
            >
              EN
            </div>
            <div
              style={{
                backgroundColor:
                  language === "ioc_scientific"
                    ? "#ffbe00"
                    : "#a07702",
              }}
              className="col-1 p-0 language-button rounded cursor-pointer"
              onClick={() => changeLanguage("ioc_scientific")}
            >
              SCI
            </div>
            <div
              style={{
                backgroundColor:
                  language === "scientific_abbreviation"
                    ? "#ffbe00"
                    : "#a07702",
              }}
              className="col-1 p-0 language-button rounded cursor-pointer"
              onClick={() =>
                changeLanguage("scientific_abbreviation")
              }
            >
              3+3
            </div>
          </div>
          {isMissingAbbreviation() && language === "scientific_abbreviation" && 
            <div style={{
                  color: "#dc3545",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  marginBottom: "5px",
                  marginTop: "-10px",
                  marginLeft: "10px",
                }}
                >
              <i
                style={{fontSize: "22px"}}
                className="bi bi-exclamation-triangle-fill"
              ></i>
              <p style={{ fontSize: "12px", transform: "translateY(7px)", paddingRight: "10px" }}>
                <span style={{display: "inline-block"}}>Lista sisältää lajeja ilman 3+3 lyhennettä.</span> {" "}
                <span style={{display: "inline-block"}}>Näissä lajeissa näytetään englanninkielinen nimi.</span>
              </p>
            </div>
            }
          <div className="mb-3 w-100 px-2">
            <input
              type="text"
              className="w-100 p-1"
              placeholder={"hae"}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="row w-100 m-0 p-0 justify-content-center main-container py-3">
        <div className="col-12 col-lg-10">
          <div className="w-100 row m-0 p-0 observations-container p-2 pb-2">
            {!observationsQuery.data && (
              <div className="row w-100 m-0 p-0 py-2 justify-content-center">
                <LoadingSpinner />
              </div>
            )}
            {observationsQuery.data && (
              <div className="m-0 p-0 table-responsive">
                <table className="table table-striped table-sm">
                  <thead>
                    <tr>
                      <th scope="col">
                        <b>#</b>
                      </th>
                      <th
                        scope="col"
                        className={
                          navPosition() === "All"
                            ? ""
                            : "table-column-pressable"
                        }
                        onClick={() => setOrderKey("systord")}
                      >
                        Laji
                      </th>
                      <th
                        scope="col"
                        className="table-column-pressable"
                        onClick={() => setOrderKey("date")}
                      >
                        Päivämäärä
                      </th>
                      <th scope="col">Paikka</th>
                      <th scope="col">Lisätiedot</th>
                      {navPosition() !== "NotReady" ? (
                        <th scope="col">Asiasanat</th>
                      ) : (
                        <></>
                      )}

                      {shouldShowTools() ? (
                        <th scope="col">toiminnot</th>
                      ) : (
                        <></>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {hasContent() === true ? (
                      (
                        filterOrderBySearch() as FilteredObservationResponse[]
                      ).map((observation, index) => {
                        return (
                          <tr key={index}>
                            <td>{`${index + 1}.`}</td>
                            <td
                              className={
                                navPosition() === "All"
                                  ? ""
                                  : "table-column-pressable"
                              }
                            >
                              <HrefWrapper
                                disabled={
                                  navPosition() === "All"
                                    ? true
                                    : false
                                }
                                href={getUserObservationsHref(
                                  observationsQuery.data.user
                                    ?.id,
                                  observation.species_id,
                                  parseInt(
                                    searchParams.get("listId") ||
                                      "0"
                                  )
                                )}
                              >
                                { language === "scientific_abbreviation" ? (
                                  observation.scientific_abbreviation && observation.scientific_abbreviation[0] ? observation.scientific_abbreviation[0] : `${observation.ioc_english || "[Lajin nimi puuttuu]"}`
                                ) : observation[language] || "[Lajin nimi puuttuu]"}
                                {
                                  language === "scientific_abbreviation" && observation.scientific_abbreviation[1] ? 
                                  <span className="secondary-abbr">{observation.scientific_abbreviation[1]}</span> 
                                  : 
                                  null
                                }
                              </HrefWrapper>
                            </td>
                            <td>{parseDate(observation)}</td>
                            <td>{getPlace(observation)}</td>
                            <td>
                              {observation.additional_info}
                            </td>
                            {navPosition() !== "NotReady" ? (
                              <td>
                                {observation.keyword_names || ""}
                              </td>
                            ) : (
                              <></>
                            )}
                            {shouldShowTools() ? (
                              <td>
                                <div className="d-flex gap-3">
                                  <HrefWrapper
                                    classname="text-end"
                                    href={
                                      editHref(observation) ||
                                      "#"
                                    }
                                  >
                                    <i className="bi bi-pencil-fill checkbox-tool-icon"></i>
                                  </HrefWrapper>
                                  <i
                                    onClick={() => {
                                      handleDeleteObs(
                                        observation.id
                                      );
                                    }}
                                    className="bi bi-trash-fill checkbox-tool-icon"
                                  ></i>
                                </div>
                              </td>
                            ) : (
                              <></>
                            )}
                            <td className="text-end">
                              <ReportButton
                                observation={observation}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={10}>
                          <div className="row w-100 m-0 p-0 py-2 justify-content-center">
                            <p className="m-0 p-0 px-5 text-start">
                              <b>Ei havaintoja...</b>
                            </p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ObservationsPage;

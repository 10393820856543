interface LatestObservationPaginationProps {
  rowsPerPage: number;
  page: number;
  showPagesCount: number;
  changePage: (page: number) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
}

/**
 * Component for showing pagination navigation for checkbox component
 * returns left arrow < right arrow > first, last and numbers for next and last showPagesCount
 * so something like < first 3 4 5 <bold>6</bold> 7 8 9 last >
 * the onclick will be implemented later
 */
function LatestObservationPagination({ rowsPerPage, page, showPagesCount, changePage, setRowsPerPage}: LatestObservationPaginationProps) {

  const minPage = () => {
    return 1;
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(e.target.value));
    localStorage.setItem("pagination_rowsPerPage", e.target.value);
  };

  const pageNumberElements = () => {
    const elements = [];
    const min = minPage();
    const current = page;
    const show = showPagesCount;
    const start = current - show + 1;
    const end = current + show + 1;
    const startPage = start < min ? min : start;

    for (let index = startPage; index <= end; index++) {
      elements.push(
        <li className="page-item" key={index}>
          <p
            className="page-link cursor-pointer"
            style={{ color: "#a07702" }}
            onClick={() => changePage(index - 1)}
          >
            {index === page + 1 ? <b>{index}</b> : index}
          </p>
        </li>
      );
    }
    return elements;
  };

  return (
    <div className="w-100 d-flex justify-content-center m-0 p-0">
      <nav className="latest-observation-pagination">
        <div style={{ display: "flex", justifyContent: "center", flex: 3 }}>
          <ul className="pagination">
            { page > 2 && (
            <li className="page-item">
              <p
                className="page-link cursor-pointer"
                aria-label="Previous"
                style={{ color: "#a07702" }}
                onClick={() => {
                  if (page > 3) {
                    changePage(page - 5);
                  } else {
                    changePage(0);
                  }
                }}
              >
                <span aria-hidden="true">&laquo;</span>
              </p>
            </li>)}
            { page > 0 && (
            <li className="page-item">
              <p
                className="page-link cursor-pointer"
                aria-label="Previous"
                style={{ color: "#a07702" }}
                onClick={() => {
                  changePage(page - 1);
                }}
              >
                <span aria-hidden="true">&lsaquo;</span>
              </p>
            </li>)}
            {pageNumberElements()}
            <li className="page-item">
              <p
                className="page-link cursor-pointer"
                style={{ color: "#a07702" }}
                aria-label="Next"
                onClick={() => {
                  changePage(page + 1);
                }}
              >
                <span aria-hidden="true">&rsaquo;</span>
              </p>
            </li>
            <li className="page-item">
              <p
                className="page-link cursor-pointer"
                aria-label="Previous"
                style={{ color: "#a07702" }}
                onClick={() => {
                  changePage(page + 5);
                }}
              >
                <span aria-hidden="true">&raquo;</span>
              </p>
            </li>
          </ul>
        </div>
        <div className="second-row">
          { //Set rows per page select options 50 100 250 500
          }
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                Riviä/siv
              </span>
            </div>
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              value={rowsPerPage.toString()}
              style={{ height: "38px", width: "65px", flex: "unset", flexWrap: "nowrap" }}
              onChange={(e) => {
                handleOnChange(e);
              }}
            >
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="300">300</option>
              <option value="400">400</option>
              <option value="500">500</option>
              <option value="600">750</option>
              <option value="1000">1000</option>
            </select>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default LatestObservationPagination;

import React, { ChangeEvent, useState } from "react";

interface IconButtonProps {
  /** bootstrap icon appears besides the input*/
  icon: string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

interface CustomOptionInputProps<T> {
  possibleValues: T[];
  valueSelected: string;
  labelKey: keyof T;
  valueKey: keyof T;
  name: string;
  placeHolder: string;
  cleanOnFocus: boolean;
  onFinishChange: (value: T | null) => void;
  icon?: IconButtonProps;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<any>) => void;
  onKeyDown?: (e: React.KeyboardEvent<any>) => void;
  interractable?: boolean;
  error?: boolean;
}

function CustomOptionInput<T>(props: CustomOptionInputProps<T>) {
  const {
    placeHolder,
    valueSelected,
    possibleValues,
    onFinishChange,
    valueKey,
    labelKey,
    name,
    cleanOnFocus,
    icon,
    onKeyUp,
    onChange,
    onKeyDown,
    interractable = true,
    error,
  } = props;

  /** Input text is used when we are editing and in focus, when not we use provided values label */
  const [inputText, setInputText] = useState<string>("");

  const [hasFocus, setHasFocus] = useState(false);

  const handleChooseClosestMatch = () => {
    if (!inputText || inputText === "") {
      onFinishChange(null);
      return;
    }
    const closestMatch = possibleValues.find((possibleValue) => {

      if (labelKey === "scientific_abbreviation") {
        return (possibleValue[labelKey] as string[]).some((sci) =>
          sci.toLowerCase().startsWith(inputText?.toLowerCase())
        );
      }

      return (possibleValue[labelKey] as unknown as string)
        .toLowerCase()
        .startsWith(inputText?.toLowerCase());
    });
    if (closestMatch) {
      onFinishChange(closestMatch);
    } else {
      onFinishChange(null);
    }
  };

  /** When blurring we finish the editing of input */
  const handleBlur = () => {
    handleChooseClosestMatch();
    setHasFocus(false);
  };

  /** When gaining focus we empty input if cleanInput is true */
  const handleFocus = () => {
    setHasFocus(true);
    if (cleanOnFocus) {
      setInputText("");
    }
  };

  /** If element is focused we return inputText if not we should return the option which matches the right option */
  const chooseCurrentValue = () => {
    if (hasFocus) {
      return inputText;
    }
    const selectedValue = possibleValues.find(
      (possibleValue) => {
        const stringified = (
          possibleValue[valueKey] as any
        ).toString();
        return stringified === valueSelected;
      }
    );
    if (selectedValue) {
      return selectedValue[labelKey] as unknown as string;
    }
    return placeHolder;
  };

  //Filter possible values by valueKey to remove duplicates
  const possibleValuesNoDuplicates = possibleValues.filter(
    (possibleValue, index) => {
      const stringified = (
        possibleValue[valueKey] as any
      ).toString();
      return (
        possibleValues.findIndex(
          (possibleValue2) =>
            (possibleValue2[valueKey] as any).toString() ===
            stringified
        ) === index
      );
    }
  );

  return (
    <div className="input-container">
      <input
        type="text"
        disabled={!interractable}
        style={error ? { border: "1px solid red" } : {}}
        value={chooseCurrentValue()}
        name={name}
        onChange={(e) => {
          setInputText(e.target.value);
          onChange && onChange(e);
        }}
        onBlur={handleBlur}
        onFocus={handleFocus}
        autoComplete="off"
        className="w-100 p-2"
        onKeyUp={(e) => {
          onKeyUp && onKeyUp(e);
        }}
        onKeyDown={(e) => {
          onKeyDown && onKeyDown(e);
        }}
        list={`${name}-data-list`}
      />
      { labelKey === "scientific_abbreviation" ? (
        <datalist id={`${name}-data-list`}>
          {possibleValuesNoDuplicates.map(
            (possibleValue, index) => {

              const values = possibleValue[labelKey] as string[];

              return values.map((value, index) => (
                <option
                  key={value}
                  value={value}
                >
                  {value}
                </option>
              ));
            }
          )}
        </datalist> 
      ) : (
        <datalist id={`${name}-data-list`}>
          {possibleValuesNoDuplicates.map(
            (possibleValue, index) => (
              <option
                key={possibleValue[valueKey] as any}
                value={possibleValue[labelKey] as any}
              >
                {possibleValue[labelKey] as any}
              </option>
            )
          )}
        </datalist> 
      )}
      {icon && (
        <div
          className="custom-input-icon-button d-flex justify-content-center align-items-center"
          onClick={icon.onClick}
          id={name}
        >
          <i className={icon.icon}></i>
        </div>
      )}
    </div>
  );
}

export default CustomOptionInput;

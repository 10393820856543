import axios, { AxiosResponse } from "axios";
import { get as lsGet } from "local-storage";
import { LoginRequest } from "../Types/apiRequest";
import {
  AssociationIndexResponse,
  ContinentIndexResponse,
  CountryIndexResponse,
  ExtendedTaxonOptionResponse,
  FetchCompareResponse,
  FetchListUserNamesResponse,
  FetchSeenSpeciesUsersResponse,
  FilteredObservationResponse,
  FilteredObservationsResponse,
  HasSeenSpeciesCountResponse,
  HasSeenSpeciesResponse,
  KeywordIndexResponse,
  LatestObservationsOnListResponse,
  listIndexResponse,
  ListShowResponse,
  MunicipalityIndexResponse,
  ObservationsForSingleSpeciesResponse,
  OwnObservationResponse,
  PostObservationResponse,
  SearchOptionResponse,
  UserInfoResponse,
  UserObservationResponse,
  UserType,
} from "../Types/apiResponses";

const BASE_URL = process.env.REACT_APP_BASE_URL || "https://pinnari.fi/api";
// const BASE_URL = "https://pinnari.fi/api";

/**
 * Initalize axios with headers and base url
 */
const initAxios = (): void => {
  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.post["Content-Type"] =
    "application/json";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] =
    "*";
  axios.defaults.headers.post["Access-Control-Allow-Methods"] =
    "GET,PUT,POST,DELETE,PATCH,OPTIONS";
  axios.defaults.headers.post["Access-Control-Allow-Headers"] =
    "Origin, X-Requested-With, Content-Type, Accept";
};

/**
 * Try post login with username and password
 * @param loginRequest
 * @returns 200 ok - empty || error
 */
const login = async (
  loginRequest: LoginRequest
): Promise<AxiosResponse<any>> => {
  return axios.post("/users/login", loginRequest);
};

const listSync = (id: number) => {
  return axios.get(`/utils/list-sync/${id}`);
};

/**
 * This could be inmplemented with proper refreshing ttl is long but it's still anoying
 */
const checkToken = async (): Promise<boolean> => {
  try {
    const token = lsGet("token") as string;
    const expires = lsGet("token_expire") as string;
    if (token === null || expires === null) return false;
    const expireAsDate = new Date(expires);
    if (expireAsDate <= new Date()) {
      return false;
    }

    axios.defaults.headers.common = {
      Authorization: `bearer ${token}`,
    };
    return true;
  } catch (error: any) {
    return false;
  }
};

const fetchLists = async (queryParams: any) => {
  const response = await axios.get(`/lists${queryParams}`);
  return response.data as Array<listIndexResponse>;
};

const fetchListsByIds = async (ids: Array<number>) => {
  const response = await axios.post("/lists/byids", {
    ids,
  });
  return response.data as Array<listIndexResponse>;
};

const fetchKeywords = async () => {
  const response = await axios.get("/lookups?keywords=on");
  return response.data.keywords as Array<KeywordIndexResponse>;
};

const fetchListUsersByList = async (id: number) => {
  const response = await axios.get(`/lists/${id}?last=on`);
  return response.data as ListShowResponse;
};

const fetchIsInList = async (id: number) => {
  const response = await axios.get(`/listuser/belongs/${id}`);
  return response.data.exists as boolean;
};

const postJoinList = async (id: number) => {
  const response = await axios.post(`/lists/${id}/join`);
  return response.data;
};

const fetchContinents = async () => {
  const response = await axios.get("/continents");
  return response.data as Array<ContinentIndexResponse>;
};
const fetchCountries = async () => {
  const response = await axios.get("/countries");
  return response.data as Array<CountryIndexResponse>;
};

const fetchMunicipalities = async () => {
  const response = await axios.get("/municipalities");
  return response.data as Array<MunicipalityIndexResponse>;
};

const fetchAssociations = async () => {
  const response = await axios.get("/associations");
  return response.data as Array<AssociationIndexResponse>;
};

const fetchObservationSearchOptions = async () => {
  const response = await axios.get(
    "/lookups?continents=on&countries=on&municipalities=on&associations=on&keywords=on&associations_extra=on"
  );
  return response.data as SearchOptionResponse;
};

const postObservation = async (
  body: any
): Promise<PostObservationResponse> => {
  const response = await axios.post("/observations", body);
  return response.data;
};

const updateObservation = async (data: any) => {
  const response = await axios.put(
    `/observations/${data.id}`,
    data.body
  );
  return response.data as any;
};

const fetchOwnObservations = async (checkTaxon = false) => {
  const response = await axios.get(
    `/observations?${checkTaxon ? "needCheck=on" : ""}`
  );
  return response.data as Array<OwnObservationResponse>;
};

const deleteObservation = async (id: number) => {
  const response = await axios.delete(`/observations/${id}`);
  return response.data as any;
};

const fetchOwnLists = async () => {
  const response = await axios.get("/lists?own=on");
  return response.data as Array<listIndexResponse>;
};

const fetchUserObservationCSV = async () => {
  const response = await axios.get("/users/csv");
  return response.data as string;
};

const fetchLastObservationsByListAndUser = async (
  listId: number,
  userId: number,
  all?: boolean
) => {
  const response = await axios.get(
    `/observations/${listId}/${userId}?${all ? "all=on" : ""}`
  );
  return response.data as Array<UserObservationResponse>;
};

const fetchUserInfo = async (userId: number) => {
  const response = await axios.get(`/users/${userId}`);
  return response.data as UserInfoResponse;
};

const postLeavelList = async (id: number) => {
  const response = await axios.post(`lists/${id}/leave`);
  return response.data as any;
};

const postHideList = async (id: number) => {
  const response = await axios.post(`lists/${id}/hide`);
  return response.data as any;
};

const fetchHasSeenSpeciesList = async (search: string) => {
  const response = await axios.get(`/taxons/seen${search}`);
  return response.data as Array<HasSeenSpeciesResponse>;
};

const fetchHasSeenSpeciesCount = async (listId: number) => {
  const response = await axios.get(
    `/observations/species/${listId}`
  );
  return response.data as Array<HasSeenSpeciesCountResponse>;
};

const fetchSingleSpeciesObservationsByUser = async (
  speciesId: number,
  userId: number
) => {
  const response = await axios.get(
    `/observations/species/${speciesId}/${userId}`
  );
  return response.data as Array<ObservationsForSingleSpeciesResponse>;
};

const fetchFilteredObservations = async (
  listId: string | null,
  userId: string | null,
  speciesId?: string | null,
  raw?: true | null
) => {
  if (!listId || !userId)
    throw new Error("Missing listId or userId");
  const response = (await axios.get(
    `/observations/${listId}/${userId}/${
      speciesId ? speciesId : ""
    }${raw ? "?raw=true" : ""}`
  )) as AxiosResponse<FilteredObservationsResponse>;

  return response.data as FilteredObservationsResponse;
};

const fetchObservationById = async (id: string) => {
  const response = await axios.get(`/observations/${id}`);
  return response.data as FilteredObservationResponse & {
    firstname: string;
    lastname: string;
    user_id: number;
  };
};

const fetchLatestObservationsOnList = async (
  listId: number,
  userId?: number,
  speciesId?: number,
  continentId?: number,
  countryId?: number,
  municipalityId? : number,
  associationId?: number,
  page?: number,
  rowsPerPage?: number
) => {
  const searchParams = new URLSearchParams();
  if (userId) searchParams.append("userId", userId.toString());
  if (speciesId) searchParams.append("speciesId", speciesId.toString());
  if (continentId) searchParams.append("continentId", continentId.toString());
  if (countryId) searchParams.append("countryId", countryId.toString());
  if (municipalityId) searchParams.append("municipalityId", municipalityId.toString());
  if (associationId) searchParams.append("associationId", associationId.toString());
  if (page) searchParams.append("page", page.toString());
  if (rowsPerPage) searchParams.append("rowsPerPage", rowsPerPage.toString());

  const response = await axios.get(
    `/observations/latest/${listId}?${searchParams}`
  );
  return response.data as LatestObservationsOnListResponse;
};

const fetchSeenSpeciesUsers = async (searchParams: any) => {
  const response = await axios.get(
    `/taxons/seen${searchParams}`
  );
  return response.data as FetchSeenSpeciesUsersResponse;
};

const fetchCompare = async (searchParams: any) => {
  const response = await axios.get(
    `/taxons/compare${searchParams}`
  );
  return response.data as FetchCompareResponse;
};

const fetchListUserNames = async (listId: string | null) => {
  const response = await axios.get(`/lists/users/${listId}`);
  return response.data as Array<FetchListUserNamesResponse>;
};

const fetchAllTaxonOptions = async () => {
  const response = await axios.get("/lookups/taxons");
  return response.data as Array<ExtendedTaxonOptionResponse>;
};
const getUser = () => {
  const user = localStorage.getItem("user");
  const userObject = user
    ? (JSON.parse(user) as UserType)
    : undefined;
  if (!userObject) throw new Error("User not found");
  return userObject;
};

const requestUserInfoChange = (type: "password" | "email") => {
  return axios.post("/users/request-change", { type });
};

const applyChange = (token: string, value: string) => {
  return axios.post(`/users/apply-change/${token}`, { value });
};

const applySettings = (settings: string) => {
  return axios.post("/users/settings", { settings });
};

const getSettings = () => {
  return axios.get("/users/settings");
};

const getListTaxons = (listId: number) => {
  return axios.get(`/lookups/taxons/${listId}`);
};

const requestPasswordChangeNoAuth = (email: string) => {
  return axios.post("/users/request-password-reset", { email });
};

const applyPasswordChangeNoAuth = (key: string) => {
  return axios.post("/users/request-password-reset/apply", {
    key,
  });
};

const fetchMotd = () => {
  return axios.get("/views/motd");
};

const fetchFlaggedObservations = async () => {
  const response = await axios.get("/observations/report/me");
  return response.data as {
    reports: Array<FilteredObservationResponse>;
    report_count: number;
  };
};

export {
  applyChange,
  applyPasswordChangeNoAuth,
  applySettings,
  checkToken,
  deleteObservation,
  fetchAllTaxonOptions,
  fetchAssociations,
  fetchCompare,
  fetchContinents,
  fetchCountries,
  fetchFilteredObservations,
  fetchFlaggedObservations,
  fetchHasSeenSpeciesCount,
  fetchHasSeenSpeciesList,
  fetchIsInList,
  fetchKeywords,
  fetchLastObservationsByListAndUser,
  fetchLatestObservationsOnList,
  fetchLists,
  fetchListsByIds,
  fetchListUserNames,
  fetchListUsersByList,
  fetchMotd,
  fetchMunicipalities,
  fetchObservationById,
  fetchObservationSearchOptions,
  fetchOwnLists,
  fetchOwnObservations,
  fetchSeenSpeciesUsers,
  fetchSingleSpeciesObservationsByUser,
  fetchUserInfo,
  fetchUserObservationCSV,
  getListTaxons,
  getSettings,
  getUser,
  initAxios,
  listSync,
  login,
  postHideList,
  postJoinList,
  postLeavelList,
  postObservation,
  requestPasswordChangeNoAuth,
  requestUserInfoChange,
  updateObservation
};

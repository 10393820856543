import { format as dateFormat } from "date-fns";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import CustomOptionInput from "../../Components/CustomInputs/CustomOptionInput";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import useLocalStorageValue from "../../Hooks/useLocalStorageValue";
import { ListResponse, SearchOptionResponse } from "../../Types/apiResponses";
import {
  fetchAllTaxonOptions,
  fetchLatestObservationsOnList,
  fetchListUserNames,
  fetchObservationSearchOptions,
} from "../../Utils/apiUtils";
import LatestObservationItem from "./LatestObservationItem";
import LatestObservationPagination from "../../Components/LatestObservationPagination";

function LatestOnListPage() {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const [language, setLanguage] = useState<
    | "ioc_finnish"
    | "ioc_english"
    | "ioc_scientific"
    | "scientific_abbreviation"
  >("ioc_finnish");

  const page = parseInt(searchParams.get("page") || "0");
  const rowsPerPage = parseInt(searchParams.get("rowsPerPage") || localStorage.getItem("pagination_rowsPerPage") || "100");

  const observationsQuery = useQuery(
    ["latest-on-list", id],
    () => 
      fetchLatestObservationsOnList(
        parseInt(id as string),
        parseInt(searchParams.get("userId") || ""),
        parseInt(searchParams.get("speciesId") || ""),
        parseInt(searchParams.get("continentId") || ""),
        parseInt(searchParams.get("countryId") || ""),
        parseInt(searchParams.get("municipalityId") || ""),
        parseInt(searchParams.get("associationId") || ""),
        parseInt(searchParams.get("page") || "0"),
        parseInt(searchParams.get("rowsPerPage") || localStorage.getItem("pagination_rowsPerPage") || "100")
      ),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      staleTime: 0,
    }
  );

  useEffect(() => {
    observationsQuery.refetch();
  }
  , [searchParams.get("page"), searchParams.get("rowsPerPage")]);

  const handlePageChange = async (newPage: number) => {
    if (newPage === page) {
      return;
    }
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set("page", newPage < 0 ? "0" : newPage.toString());
      return newParams;
    });
  };
  
  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    if (newRowsPerPage === rowsPerPage) {
      return;
    }
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set("rowsPerPage", newRowsPerPage.toString());
      newParams.set("page", "0");
      return newParams;
    });
  };
  
  const taxonsQuery = useQuery(
    ["taxons-on-list"],
    () => fetchAllTaxonOptions(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const userQuery = useQuery(
    ["users-on-list", id],
    () => fetchListUserNames(id as string),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    }
  );

  const locationQuery = useQuery(
    ["location-on-list", id],
    () => fetchObservationSearchOptions(),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    }
  );

  const getLocationSearchParam = () => {
    const continent = searchParams.get("continentId");
    const country = searchParams.get("countryId");
    const municipality = searchParams.get("municipalityId");
    const association = searchParams.get("associationId");
    
    if (municipality) {
      return `municipality-${municipality}`;
    }
    if (association) {
      return `association-${association}`;
    }
    if (country) {
      return `country-${country}`;
    }
    if (continent) {
      return `continent-${continent}`;
    }
    return "";
  };

  const flattenLocations = (locations: SearchOptionResponse): Array<{name: string, id: string}> => {
    const continents = locations.continents.map((continent) => ({name: continent.name, id: `continent-${continent.id}`}));
    const countries = locations.countries.map((country) => ({name: country.name, id: `country-${country.id}`}));
    const municipalities = locations.municipalities.map((municipality) => ({name: municipality.name, id: `municipality-${municipality.id}`}));
    const associations = locations.associations.map((association) => ({name: association.name, id: `association-${association.id}`}));

    return [...continents, ...countries, ...municipalities, ...associations];
  };

  const [defaultLanguage, setDefaultLanguage] =
    useLocalStorageValue<
      | "ioc_finnish"
      | "ioc_english"
      | "ioc_scientific"
      | "scientific_abbreviation"
      | null
    >("editor-default-language", null);

  useEffect(() => {
    if (defaultLanguage) {
      setLanguage(defaultLanguage);
    }
  }, []);

  const handleLanguageChange = (
    language:
      | "ioc_finnish"
      | "ioc_english"
      | "ioc_scientific"
      | "scientific_abbreviation"
  ) => {
    setLanguage(language);
    setDefaultLanguage(language);
  };

  useEffect(() => {
    queryClient.invalidateQueries(["latest-on-list", "*"]);
  }, [
    searchParams.get("userId"),
    searchParams.get("speciesId"),
    id,
  ]);

  if (
    userQuery.isFetching ||
    !userQuery.data ||
    taxonsQuery.isFetching ||
    !taxonsQuery.data ||
    locationQuery.isFetching ||
    !locationQuery.data
  )
    return (
      <div className="w-100 d-flex justify-content-center">
        <LoadingSpinner />
      </div>
    );

  const { list, observations, user } = observationsQuery.data ? observationsQuery.data : { list: null, observations: [], user: null };
  const users = userQuery.data;

  const parseDate = (list: ListResponse) => {
    let date_from = list.date_from;
    let date_to = list.date_to;
    const interval_monthday_from = list.interval_monthday_from;
    const inteval_monthday_to = list.interval_monthday_to;

    if (date_from) {
      date_from = dateFormat(new Date(date_from), "dd.MM.yyyy");
    }
    if (date_to) {
      date_to = dateFormat(new Date(date_to), "dd.MM.yyyy");
    }

    if (date_from || date_to) {
      if (date_from && date_to && date_from !== date_to) {
        return `${date_from} - ${date_to}`;
      } else {
        return date_to;
      }
    }
    if (interval_monthday_from && inteval_monthday_to) {
      /** From MMDD|MDD integer to string dd-MM */
      const formattedFrom = interval_monthday_from
        .toString()
        .padStart(4, "0")
        .replace(/(\d{2})(\d{2})/, "$2.$1");
      const formattedTo = inteval_monthday_to
        .toString()
        .padStart(4, "0")
        .replace(/(\d{2})(\d{2})/, "$2.$1");
      return `${formattedFrom} - ${formattedTo}`;
    }
    return "0.0.0000";
  };

  return (
    <>
      <div className="row w-100 m-0 p-0 justify-content-center my-2">
        <div className="col-12 col-lg-8">
          <div className="w-100 row m-0 p-0 p-2">
            <div className="w-100">
              <p className="p-0 m-0">
                <b>Lista: </b>
                {list?.name || "..."}
              </p>
            </div>
            <div className="w-100">
              <p className="p-0 m-0">
                <b>Kuvaus: </b>
                {list?.description || "..."}
              </p>
            </div>
            <div className="w-100">
              <p className="p-0 m-0">
                <b>Käyttäjä: </b>
                {(user?.firstname &&
                  user?.lastname &&
                  `${user.firstname} ${user.lastname}`) ||
                  "..."}
              </p>
            </div>
            <div className="w-100">
              <p className="p-0 m-0">
                <b>Laji: </b>
                {taxonsQuery.data.find(
                  (taxon) =>
                    taxon.id ===
                    parseInt(searchParams.get("speciesId") || "")
                )?.ioc_finnish || "..."}
              </p>
            </div>
            <div className="w-100">
              <p className="p-0 m-0">
                <b>Aika: </b>
                {list ? parseDate(list) : "..."}
              </p>
            </div>
          </div>
          <div className="w-100 text-center gap-2 row justify-content-start m-0 p-0 px-2 pt-1 pb-3">
            <div
              className="col-2 col-sm-1 p-0 language-button rounded cursor-pointer"
              onClick={() => handleLanguageChange("ioc_finnish")}
              style={{
                backgroundColor:
                  language === "ioc_finnish"
                    ? "#ffbe00"
                    : "#a07702",
              }}
            >
              FI
            </div>
            <div
              className="col-2 col-sm-1 p-0 language-button rounded cursor-pointer"
              onClick={() => handleLanguageChange("ioc_english")}
              style={{
                backgroundColor:
                  language === "ioc_english"
                    ? "#ffbe00"
                    : "#a07702",
              }}
            >
              EN
            </div>
            <div
              style={{
                backgroundColor:
                  language === "ioc_scientific"
                    ? "#ffbe00"
                    : "#a07702",
              }}
              className="col-2 col-sm-1 p-0 language-button rounded cursor-pointer"
              onClick={() =>
                handleLanguageChange("ioc_scientific")
              }
            >
              SCI
            </div>
            <div
              style={{
                backgroundColor:
                  language === "scientific_abbreviation"
                    ? "#ffbe00"
                    : "#a07702",
              }}
              className="col-2 col-sm-1 p-0 language-button rounded cursor-pointer"
              onClick={() =>
                handleLanguageChange("scientific_abbreviation")
              }
            >
              3+3
            </div>
          </div>
          <div className="row w-100 m-0 p-0 gap-1 mb-3 align-items-baseline">
            <div className="col-12 col-sm-3 p-0">
              <CustomOptionInput
                possibleValues={
                  users.map((user) => ({
                    name: `${user.firstname} ${user.lastname}`,
                    value: user.id,
                  })) || []
                }
                valueSelected={searchParams.get("userId") || ""}
                labelKey="name"
                valueKey="value"
                name="person select"
                placeHolder={"Valitse käyttäjä"}
                cleanOnFocus={true}
                onFinishChange={(user) => {
                  if (!user?.value) {
                    searchParams.delete("userId");
                    setSearchParams(searchParams);
                    return;
                  }
                  if (
                    user.value.toString() ===
                    searchParams.get("userId")
                  ) {
                    return;
                  }
                  searchParams.set(
                    "userId",
                    user?.value.toString()
                  );
                  setSearchParams(searchParams);
                }}
              />
            </div>
            <div className="col-12 col-sm-3 p-0 m-0">
              <CustomOptionInput
                cleanOnFocus={true}
                possibleValues={taxonsQuery.data
                  .filter((taxon) => {
                    if (list && list.country_id === 162) {
                      return taxon.fin === 1;
                    } else if (list && list.is_wp) {
                      return taxon.wp === list.is_wp;
                    } else {
                      return true;
                    }
                  })
                  .sort(
                    (a, b) => {
                      return language === "scientific_abbreviation" ? a.scientific_abbreviation[0].length : a[language]?.length - b[language]?.length;
                    }
                  )}
                valueSelected={
                  searchParams.get("speciesId") || ""
                }
                labelKey={language}
                valueKey="id"
                name="specieFilter"
                placeHolder="Laji"
                onFinishChange={(specie) => {
                  if (!specie?.id) {
                    searchParams.delete("speciesId");
                    setSearchParams(searchParams);
                    return;
                  }
                  if (
                    specie.id.toString() ===
                    searchParams.get("speciesId")
                  ) {
                    return;
                  }
                  searchParams.set(
                    "speciesId",
                    specie?.id.toString()
                  );
                  setSearchParams(searchParams);
                }}
              />
            </div>
              <div className="col-12 col-sm-3 p-0 m-0">
              <CustomOptionInput
                cleanOnFocus={true}
                possibleValues={
                  flattenLocations(locationQuery.data)}
                valueSelected={
                  getLocationSearchParam()
                }
                labelKey="name"
                valueKey="id"
                name="locationFilter"
                placeHolder="Paikka"
                onFinishChange={(location) => {

                  if (!location?.id) {
                    searchParams.delete("continentId");
                    searchParams.delete("countryId");
                    searchParams.delete("municipalityId");
                    searchParams.delete("associationId");
                    setSearchParams(searchParams);
                    return;
                  }

                  switch (location.id.split("-")[0]) {
                    case "continent":
                      if ( searchParams.get("continentId") === location.id.split("-")[1]) {
                        return;
                      }
                      searchParams.set("continentId", location.id.split("-")[1]);
                      searchParams.delete("countryId");
                      searchParams.delete("municipalityId");
                      searchParams.delete("associationId");
                      break;
                    case "country":
                      if ( searchParams.get("countryId") === location.id.split("-")[1]) {
                        return;
                      }
                      searchParams.set("countryId", location.id.split("-")[1]);
                      searchParams.delete("continentId");
                      searchParams.delete("municipalityId");
                      searchParams.delete("associationId");
                      break;
                    case "municipality":
                      if ( searchParams.get("municipalityId") === location.id.split("-")[1]) {
                        return;
                      }
                      searchParams.set("municipalityId", location.id.split("-")[1]);
                      searchParams.delete("continentId");
                      searchParams.delete("countryId");
                      searchParams.delete("associationId");
                      break;
                    case "association":
                      if ( searchParams.get("associationId") === location.id.split("-")[1]) {
                        return;
                      }
                      searchParams.set("associationId", location.id.split("-")[1]);
                      searchParams.delete("continentId");
                      searchParams.delete("countryId");
                      searchParams.delete("municipalityId");
                      break;
                    default:
                      break;

                    }

                  setSearchParams(searchParams);
                }}
              />
            </div>
            <div className="col-12 col-sm-2">
              <button
                className="w-100 p-0 py-2"
                onClick={() => {
                  if (id) {
                    searchParams.set("page", "0");
                    setSearchParams(searchParams);
                    observationsQuery.refetch();
                  }
                }}
              >
                Hae
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row w-100 m-0 p-0  main-container justify-content-center">
        <div className="col-12 col-md-8 px-1 py-3">
          <LatestObservationPagination
            rowsPerPage={rowsPerPage}
            page={page}
            showPagesCount={2}
            changePage={handlePageChange}
            setRowsPerPage={handleRowsPerPageChange}
          />
          <div className="w-100 row m-0 p-0 p-2 observations-container">
            {observationsQuery.isFetching ? (
              <div className="w-100 d-flex justify-content-center">
                <LoadingSpinner />
              </div>
            )
            :
            observations && observations.length > 0 ? (
              <div className="table-responsive m-0 p-0">
                <table className="table table-striped table-sm">
                  <thead>
                    <tr>
                      <th scope="col">Laji</th>
                      <th scope="col">Havannoitsija</th>
                      <th scope="col">Paikka</th>
                      <th scope="col">Aika</th>
                      <th scope="col">Lisätiedot</th>
                    </tr>
                  </thead>
                  <tbody>
                    {observations.map((observation) => (
                      <LatestObservationItem
                        language={language}
                        observation={observation}
                        listId={id as string}
                        key={observation.id}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="col-12 m-0 p-0">
                <p className="m-0 p-0 text-center">
                  <b>Ei havaintoja</b>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default LatestOnListPage;
import { useEffect, useState } from "react";
import { applySettings } from "../Utils/apiUtils";

export interface UserListOrder {
  name: string;
  order: number[];
}

export interface UserSettings {
  hiddenListIds: number[];
  userListOrders: UserListOrder[];
}

function isUserListOrder(obj: any): obj is UserListOrder {
  return (
    obj &&
    typeof obj === "object" &&
    typeof obj.name === "string" &&
    Array.isArray(obj.order) &&
    obj.order.every(
      (element: any) => typeof element === "number"
    )
  );
}

export function isUserSettings(obj: any): obj is UserSettings {
  return (
    obj &&
    typeof obj === "object" &&
    Array.isArray(obj.hiddenListIds) &&
    obj.hiddenListIds.every(
      (element: any) => typeof element === "number"
    ) &&
    Array.isArray(obj.userListOrders) &&
    obj.userListOrders.every(isUserListOrder)
  );
}

const useUserSettings = () => {
  const [loadedOrder, setLoadedOrder] =
    useState<UserListOrder | null>(null);
  const [userSettings, setUserSettings] =
    useState<UserSettings | null>(null);

  useEffect(() => {
    if (
      !userSettings?.hiddenListIds ||
      !userSettings.userListOrders
    )
      return;
    if (userSettings.userListOrders) {
      setLoadedOrder(userSettings.userListOrders[0]);
    }
    persistUserSettingsToLS();
  }, [
    userSettings?.hiddenListIds,
    userSettings?.userListOrders,
    loadedOrder,
  ]);

  const validateUserSettings = (settings: UserSettings) => {
    if (settings.hiddenListIds && settings.userListOrders) {
      return true;
    }
    return false;
  };

  const loadUserSettingsFromLs = () => {
    const settings = localStorage.getItem("userSettings");
    const newSettings = parseOldSettingsFormat();
    if (!settings) {
      setUserSettings(newSettings);
      persistUserSettingsToLS();
      return;
    }
    let parsedSettings;
    try {
      parsedSettings = JSON.parse(settings);
    } catch {
      setUserSettings(newSettings);
      persistUserSettingsToLS();
      return;
    }
    if (!validateUserSettings(parsedSettings)) {
      setUserSettings(newSettings);
      persistUserSettingsToLS();
      return;
    }
    try {
      setUserSettings(parsedSettings);
      setLoadedOrder(
        (parsedSettings as UserSettings).userListOrders[0]
      );
    } catch {
      setUserSettings(newSettings);
      persistUserSettingsToLS();
      return;
    }
  };

  const persistUserSettingsToLS = () => {
    if (userSettings) {
      localStorage.setItem(
        "userSettings",
        JSON.stringify(userSettings)
      );
    }
  };

  const removeUserListOrder = (name: string) => {
    if (!userSettings) return;
    const newLists = userSettings?.userListOrders.filter(
      (listOrder: UserListOrder) => {
        return listOrder.name !== name;
      }
    );
    setUserSettings({
      ...userSettings,
      userListOrders: newLists,
    });
  };

  const addUserListOrder = (name: string) => {
    if (!userSettings) return;
    const alreadyExists =
      userSettings?.userListOrders.findIndex(
        (listOrder: UserListOrder) => {
          return listOrder.name == name;
        }
      ) !== -1;

    if (alreadyExists) return;

    const newListOrder: UserListOrder = {
      name,
      order: [],
    };

    const newUserSettings: UserSettings = {
      ...userSettings,
      userListOrders: [
        ...userSettings.userListOrders,
        newListOrder,
      ],
    };

    setUserSettings(newUserSettings);
  };

  const toggleHiddenList = (id: number) => {
    const hiddenLists = userSettings?.hiddenListIds;
    if (!hiddenLists) return;
    const isInHidden = hiddenLists.indexOf(id) !== -1;
    let newHidden = hiddenLists;
    if (isInHidden) {
      newHidden = hiddenLists.filter((list) => list !== id);
    } else {
      newHidden.push(id);
    }

    applySettings(JSON.stringify({
      ...userSettings,
      hiddenListIds: newHidden,
    }));

    setUserSettings({
      ...userSettings,
      hiddenListIds: newHidden,
    });

  };

  const getHiddenLists = () => {
    if (userSettings) {
      return userSettings.hiddenListIds;
    }
    return [];
  };

  const chooseLoadedOrder = (order: UserListOrder) => {
    setLoadedOrder(order);
  };

  const changeLoadedOrder = (newOrder: UserListOrder) => {
    const orderFromSettings =
      userSettings?.userListOrders.findIndex((listOrder) => {
        return listOrder.name === newOrder.name;
      });

    if (
      orderFromSettings === undefined ||
      orderFromSettings === -1 ||
      !userSettings
    ) {
      return;
    }
    const newUserSettings = userSettings;

    newUserSettings.userListOrders[orderFromSettings].order =
      newOrder.order;

    setLoadedOrder(newOrder);
    setUserSettings(newUserSettings);
  };

  const setOrderAsDefault = (name: string) => {
    if (!userSettings) return;
    const orderToDefault = userSettings.userListOrders.findIndex(
      (listOrder) => {
        return listOrder.name === name;
      }
    );
    if (orderToDefault === undefined || orderToDefault === -1)
      return;
    const newUserSettings = userSettings;
    const orderToMove =
      newUserSettings.userListOrders[orderToDefault];
    newUserSettings.userListOrders.splice(orderToDefault, 1);
    newUserSettings.userListOrders.unshift(orderToMove);
    setUserSettings(newUserSettings);
    changeLoadedOrder(newUserSettings.userListOrders[0]);
    persistUserSettingsToLS();
  };

  //Old settings format has list-header-hidden and list-header-order keys with number arrays in localStorage
  const parseOldSettingsFormat = (): UserSettings => {
    const newSettings: UserSettings = {
      hiddenListIds: [],
      userListOrders: [],
    };

    let hiddenLists;
    let listOrders;

    try {
      hiddenLists = JSON.parse(
        localStorage.getItem("list-header-hidden") || "[]"
      );
      listOrders = JSON.parse(
        localStorage.getItem("list-header-order") || "[]"
      );
    } catch {
      hiddenLists = [];
      listOrders = [];
    }

    newSettings.hiddenListIds = hiddenLists;
    newSettings.userListOrders = [
      {
        name: "Oletus",
        order: listOrders,
      },
    ];

    return newSettings;
  };

  const forceUserSettings = (userSettings: UserSettings) => {
    setUserSettings(userSettings);
  };

  return {
    loadedOrder,
    userSettings,
    forceUserSettings,
    changeLoadedOrder,
    chooseLoadedOrder,
    loadUserSettingsFromLs,
    addUserListOrder,
    removeUserListOrder,
    setOrderAsDefault,
    toggleHiddenList,
    getHiddenLists,
  };
};

export default useUserSettings;

import clsx from "clsx";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import CustomDateInput from "../../Components/CustomInputs/CustomDateInput";
import CustomFreeInput from "../../Components/CustomInputs/CustomFreeInput";
import CustomOptionInput from "../../Components/CustomInputs/CustomOptionInput";
import {
  AssociationIndexResponse,
  ContinentIndexResponse,
  CountryIndexResponse,
  FilteredObservationResponse,
  KeywordIndexResponse,
  ListResponse,
  MunicipalityIndexResponse,
} from "../../Types/apiResponses";
import ObservationKeywordBubble from "./ObservationKeywordBubble";

interface ICheckboxEditorInputProps {
  list: ListResponse;
  lockedFields: Array<string>;
  keywords: Array<KeywordIndexResponse>;
  continents: Array<ContinentIndexResponse>;
  countries: Array<CountryIndexResponse>;
  associations: Array<AssociationIndexResponse>;
  municipalities: Array<MunicipalityIndexResponse>;
  extraMunicipalities: Array<MunicipalityIndexResponse>;
  taxons: Partial<
    FilteredObservationResponse & {
      isFromObs?: boolean;
    }
  >[];
  handleInputChange: (name: string, value?: any) => void;
}

function CheckboxEditorInputs(props: ICheckboxEditorInputProps) {
  const {
    list,
    lockedFields,
    handleInputChange,
    keywords,
    continents,
    countries,
    associations,
    municipalities,
    extraMunicipalities,
    taxons,
  } = props;
  const [searchParams] = useSearchParams();
  const {
    name,
    description,
    real_obs,
    date_from,
    date_to,
    interval_monthday_from,
    interval_monthday_to,
    continent_name,
    country_name,
    association_name,
    municipality_name,
    is_wp,
  } = list;

  const [view, setView] = useState<"info" | "inputs">("info");
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [mupltipleAssociations, setMultipleAssociations] = useState<boolean>(false);

  const placeString = () => {
    const result = [];
    if (continent_name) {
      result.push(continent_name);
    }
    if (country_name) {
      result.push(country_name);
    }
    if (association_name) {
      result.push(association_name);
    }
    if (municipality_name) {
      result.push(municipality_name);
    }
    /** Join with , or if none return ... */
    if (result.length === 0) {
      return "...";
    }
    return result.join(", ");
  };

  /**
   * Check serachparams keyword, if in list dont add
   * if not add, seperate keyword ids by ,
   */
  const handleKeywordChange = (
    keywordId: string | undefined,
    action: "ADD" | "REMOVE"
  ) => {
    const currentKeyword = searchParams.get("keywords");

    const keywordArray = currentKeyword
      ? currentKeyword.split(",")
      : [];
    if (keywordId === undefined) return;

    if (action === "ADD") {
      if (!keywordArray.includes(keywordId)) {
        keywordArray.push(keywordId);
      }
    } else {
      const index = keywordArray.indexOf(keywordId);
      if (index > -1) {
        keywordArray.splice(index, 1);
      }
    }
    if (keywordArray.length === 0) {
      handleInputChange("keywords", undefined);
    } else {
      handleInputChange("keywords", keywordArray.join(","));
    }
  };

  const keywordsFromParams = () => {
    const currentKeyword = searchParams.get("keywords");
    const keywordArray = currentKeyword
      ? currentKeyword.split(",")
      : [];
    const result = keywords.filter((keyword) => {
      return keywordArray.includes(keyword.id.toString());
    });
    return result;
  };

  const isLocked = (name: string) => {
    return lockedFields.includes(name);
  };

  const isMissingAbbreviation = () => {

    let missingAbbreviation = false;

    taxons.forEach((taxon) => {
      if (taxon.scientific_abbreviation === undefined || taxon.scientific_abbreviation[0] === "") {
        missingAbbreviation = true;
      }
    });
    return missingAbbreviation;
  };

  const dateValidator = (date: Date) => {
    let success = true;
    
    if (date_from) {
      const dateFrom = new Date(new Date(date_from).toDateString());
      if (date < dateFrom) {
        success = false;
      }
    }
    
    if (date_to) {
      const dateTo = new Date(new Date(date_to).toDateString());
      if (date > dateTo) {
        success = false;
      }
    }
    
    if (!date_from && !date_to) {
      const interval_from = interval_monthday_from?.toString().padStart(4, "0");
      const interval_to = interval_monthday_to?.toString().padStart(4, "0");
      const dateInFormat = (date.getMonth() + 1).toString().padStart(2, "0") + 
                          date.getDate().toString().padStart(2, "0");
  
      if (interval_from && interval_to) {
        if (parseInt(interval_from) > parseInt(interval_to)) {
          success = dateInFormat >= interval_from || dateInFormat <= interval_to;
        } else {
          success = dateInFormat >= interval_from && dateInFormat <= interval_to;
        }
      }
    }
    
    if (date > new Date()) {
      success = false;
    }
    
    if (!success) {
      Swal.fire({
        icon: "error",
        title: "Virheellinen päivämäärä!",
        text: "Päivämäärä ei sovi listaan tai se on tulevaisuudessa",
      });
      return false;
    }
    
    return true;
  };

  const handleContinentChange = (value: ContinentIndexResponse | null) => {
    if (value) {
      handleInputChange("continent", value.id);
      handleInputChange("country", undefined);
      handleInputChange("association", undefined);
      handleInputChange("municipality", undefined);
      setMultipleAssociations(false);
    } else {
      handleInputChange("continent", undefined);
      handleInputChange("country", undefined);
      handleInputChange("association", undefined);
      handleInputChange("municipality", undefined);
      setMultipleAssociations(false);
    }
  };

  const handleCountryChange = (value: CountryIndexResponse | null) => {
    if (value) {
      handleInputChange("continent", value.continent_id);
      handleInputChange("country", value.id);
      handleInputChange("association", undefined);
      handleInputChange("municipality", undefined);
      setMultipleAssociations(false);
    } else {
      handleInputChange("country", undefined);
      handleInputChange("association", undefined);
      handleInputChange("municipality", undefined);
      setMultipleAssociations(false);
    }
  };

  const handleAssociationChange = (value: AssociationIndexResponse | null) => {
    if (value) {

      const country = countries.find((country) => country.id === value.country_id);

      handleInputChange("continent", country?.continent_id);
      handleInputChange("country", value.country_id);
      handleInputChange("association", value.id);
      setMultipleAssociations(false);
    } else {
      handleInputChange("association", undefined);
      handleInputChange("municipality", undefined);
      setMultipleAssociations(false);
    }
  };

  const handleMunicipalityChange = (value: MunicipalityIndexResponse | null) => {
    if (value) {

      const extras = extraMunicipalities.filter((municipality) => municipality.id === value.id);
      const association = associations.find((association) => association.id === value.association_id);
      const country = countries.find((country) => country.id === association?.country_id);

      country?.continent_id && handleInputChange("continent", country?.continent_id);
      association?.country_id && handleInputChange("country", association?.country_id);

      if (extras.length === 0) {
        handleInputChange("association", value.association_id);
      } else if (!searchParams.get("association")) {
        setMultipleAssociations(true);
      }

      handleInputChange("municipality", value.id);

    } else {
      handleInputChange("municipality", undefined);
      setMultipleAssociations(false);
    }
  };

  return (
    <div className="sticky-top check-box-float-container">
      <div
        className={clsx(
          "w-75 m-0 p-0 mx-auto row justify-content-center align-items-end my-3 py-1",
          {
            "d-none": collapsed,
          }
        )}
      >
        <div
          className={clsx("col-12 col-md-6 gap-3 d-md-block", {
            "d-none": view !== "info",
          })}
        >
          <p className="my-1">
            <b>Lista: </b>
            {name}
          </p>
          <p className="my-1">
            <b>Kuvaus: </b>
            {description}
          </p>
          <p className="my-1">
            <b>Paikka: </b>
            {placeString()}
          </p>
          <p className="my-1">
            <b>WP: </b>
            {is_wp ? "Kyllä" : "Ei"}
          </p>
          <p className="my-1">
            <b>Vain todellisia: </b>
            {real_obs ? "Kyllä" : "Ei"}
          </p>
          <p className="mt-3 mb-1">
            <b>Rajaukset:</b>
          </p>
          <div className="m-0 p-0 my-1">
            <div className="input-container">
              <select
                className="fselect w-100 p-2"
                name="checkbox-seen"
                value={searchParams.get("limit") || ""}
                onChange={(e) => {
                  handleInputChange("limit", e.target.value);
                }}
              >
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
            </div>
          </div>
          <div className="col-12 m-0 p-0 my-2">
            <div className="input-container">
              <select
                className="fselect w-100 p-2"
                name="checkbox-seen"
                value={searchParams.get("language") || ""}
                onChange={(e) => {
                  handleInputChange("language", e.target.value);
                }}
              >
                <option value="ioc_finnish">Suomi</option>
                <option value="ioc_english">Englanti</option>
                <option value="ioc_scientific">
                  Tieteellinen
                </option>
                <option value="scientific_abbreviation">
                  3+3
                </option>
              </select>
              { isMissingAbbreviation() && searchParams.get("language") === "scientific_abbreviation" && 
              <div style={{
                    color: "#dc3545",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    marginBottom: "-5px",
                  }}
                  >
                <i
                  style={{fontSize: "22px"}}
                  className="bi bi-exclamation-triangle-fill"
                ></i>
                <p style={{ fontSize: "12px", transform: "translateY(7px)", paddingRight: "10px" }}>
                  <span style={{display: "inline-block"}}>Lista sisältää lajeja ilman 3+3 lyhennettä.</span> {" "}
                  <span style={{display: "inline-block"}}>Näissä lajeissa näytetään englanninkielinen nimi.</span>
                </p>
              </div>
              }
            </div>
          </div>
          <div className="col-12 m-0 p-0 my-2">
            <div className="input-container">
              <select
                className="fselect w-100 p-2"
                name="checkbox-seen"
                value={searchParams.get("seenOnList") || ""}
                onChange={(e) => {
                  handleInputChange(
                    "seenOnList",
                    e.target.value
                  );
                }}
              >
                <option value="">Kaikki</option>
                <option value="true">Vain nähdyt</option>
              </select>
            </div>
          </div>
          <div className="m-0 p-0 mt-2 mb-1">
            <CustomFreeInput
              name="checkbox-search"
              valueSelected={searchParams.get("search") || ""}
              onFinishChange={(value) => {
                handleInputChange("search", value);
              }}
              cleanOnFocus={true}
              placeHolder="Hakusana"
            />
          </div>
          <div className="m-0 p-0 col-6"></div>
        </div>
        <div
          className={clsx("col-12 col-md-6 gap-3 d-md-block", {
            "d-none": view !== "inputs",
          })}
        >
          <p>
            <b>Havainnon tiedot: </b>
          </p>
          <div className="row w-100">
            <div className="col-6 m-0 p-0 px-1">
              <CustomDateInput
                cleanOnFocus={true}
                name="checkbox-date"
                valueSelected={searchParams.get("date") || ""}
                onFinishChange={(value) => {
                  handleInputChange("date", value);
                }}
                placeHolder="pp.kk.vvvv"
                validate={(date: Date) => {
                  return dateValidator(date);
                }}
              />
            </div>
            <div className="col-6 m-0 p-0 px-1">
              <CustomOptionInput
                cleanOnFocus={true}
                interractable={!isLocked("keyword")}
                possibleValues={keywords}
                labelKey="name"
                valueKey="id"
                name="checkbox-keyword"
                valueSelected={""}
                onFinishChange={(value) => {
                  handleKeywordChange(
                    value?.id.toString(),
                    "ADD"
                  );
                }}
                placeHolder={
                  isLocked("keyword")
                    ? "[Asiasana listasta]"
                    : "Asiasana"
                }
              />
            </div>
            <div className="col-12 m-0 p-0 my-1">
              <div className="d-flex align-items-center justify-content-center">
                {isLocked("keyword") === false &&
                  keywordsFromParams().map((keyword, index) => {
                    return (
                      <ObservationKeywordBubble
                        key={index}
                        id={keyword.id.toString()}
                        text={keyword.name}
                        onClick={() => {
                          handleKeywordChange(
                            keyword.id.toString(),
                            "REMOVE"
                          );
                        }}
                      />
                    );
                  })}
              </div>
            </div>
            <div className="col-6 m-0 p-0 my-1 px-1">
              <CustomOptionInput
                interractable={false}
                cleanOnFocus={true}
                possibleValues={[
                  { name: "WP", value: 1 },
                  { name: "Ei WP", value: 2 },
                  { name: "Maailma", value: 3 },
                ]}
                labelKey="name"
                valueKey="value"
                name="checkbox-area"
                placeHolder="Alue"
                onFinishChange={(value) => {
                  handleInputChange("area", value?.value);
                }}
                valueSelected={searchParams.get("area") || ""}
              />
            </div>
            <div className="col-6 m-0 p-0 my-1 px-1">
              <CustomOptionInput
                interractable={!isLocked("continent_id")}
                cleanOnFocus={true}
                possibleValues={continents}
                labelKey="name"
                valueKey="id"
                name="checkbox-continent"
                placeHolder="Maanosa"
                onFinishChange={(value) => {
                  handleContinentChange(value);
                }}
                valueSelected={
                  searchParams.get("continent") || ""
                }
              />
            </div>
            <div className="col-6 m-0 p-0 my-1 px-1">
              <CustomOptionInput
                interractable={!isLocked("country_id")}
                cleanOnFocus={true}
                possibleValues={countries}
                labelKey="name"
                valueKey="id"
                name="checkbox-country"
                placeHolder="Maa"
                onFinishChange={(value) => {
                  handleCountryChange(value);
                }}
                valueSelected={searchParams.get("country") || ""}
              />
            </div>
            <div className="col-6 m-0 p-0 my-1 px-1">
              <CustomOptionInput
                interractable={!isLocked("association_id")}
                cleanOnFocus={true}
                possibleValues={associations}
                labelKey="name"
                valueKey="id"
                error={mupltipleAssociations}
                name="checkbox-association"
                placeHolder="Yhdistys"
                onFinishChange={(value) => {
                  handleAssociationChange(value);
                }}
                valueSelected={
                  searchParams.get("association") || ""
                }
              />
              { mupltipleAssociations &&
              <div style={{
                    color: "#dc3545",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    marginBottom: "-5px",
                    position: "absolute",
                  }}
                  >
                <p style={{ fontSize: "12px", transform: "translateY(7px)", paddingRight: "10px" }}>
                  <span style={{display: "inline-block"}}>Monta vaihtoehtoa</span> {" "}
                </p>
              </div>
              }
            </div>
            <div className="col-6 m-0 p-0 my-1 px-1">
              <CustomOptionInput
                interractable={!isLocked("municipality_id")}
                cleanOnFocus={true}
                possibleValues={municipalities}
                labelKey="name"
                valueKey="id"
                name="checkbox-municipality"
                placeHolder="Kunta"
                onFinishChange={(value) => {
                  handleMunicipalityChange(value);
                }}
                valueSelected={
                  searchParams.get("municipality") || ""
                }
              />
            </div>
            <div className="col-12 m-0 p-0 my-1 px-1">
              <CustomFreeInput
                cleanOnFocus={false}
                name="checkbox-additionalinfo"
                placeHolder="Lisätiedot"
                onFinishChange={(value) => {
                  handleInputChange("additional_info", value);
                }}
                valueSelected={
                  searchParams.get("additional_info") || ""
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="d-md-none w-75 m-0 p-0 mx-auto">
        <button
          onClick={() => {
            if (view === "info") {
              setView("inputs");
            } else {
              setView("info");
            }
          }}
          className={clsx("py-1 w-100", {
            "d-none": collapsed,
          })}
        >
          {
            {
              info: "Havainto",
              inputs: "Tiedot",
            }[view]
          }
        </button>
      </div>
      <div className="w-100 m-0 p-0 row justify-content-center">
        <button
          onClick={() => {
            setCollapsed(!collapsed);
          }}
          className="py-1 w-100"
          style={{
            maxWidth: "300px",
          }}
        >
          {collapsed ? "näytä" : "piilota"}
        </button>
      </div>
    </div>
  );
}

export default CheckboxEditorInputs;

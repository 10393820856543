import { parseInt } from "lodash";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  AssociationExtraResponse,
  AssociationIndexResponse,
  ContinentIndexResponse,
  CountryIndexResponse,
  ExtendedTaxonOptionResponse,
  KeywordIndexResponse,
  MunicipalityIndexResponse,
  SearchOptionResponse,
} from "../Types/apiResponses";
import {
  fetchAllTaxonOptions,
  fetchObservationSearchOptions,
} from "../Utils/apiUtils";

type Collection =
  | ExtendedTaxonOptionResponse
  | ContinentIndexResponse
  | CountryIndexResponse
  | MunicipalityIndexResponse
  | AssociationIndexResponse
  | KeywordIndexResponse
  | AssociationExtraResponse;

interface SubsetKV {
  key: string;
  value: string | number;
}

export type CollectionName =
  | keyof SearchOptionResponse
  | "taxons";

const useOptions = () => {
  const [isReady, setIsReady] = useState(false);

  const optionsCollectionsQuery = useQuery(
    "observation-options",
    fetchObservationSearchOptions,
    {
      refetchOnWindowFocus: false,
    }
  );
  const taxonOptionsQuery = useQuery(
    "observation-taxon-options",
    fetchAllTaxonOptions,
    {
      refetchOnWindowFocus: false,
    }
  );

  /**Wait for queries to finish before any usage is allowed */
  useEffect(() => {
    if (optionsCollectionsQuery.data && taxonOptionsQuery.data) {
      setIsReady(true);
    } else {
      setIsReady(false);
    }
  }, [optionsCollectionsQuery.data, taxonOptionsQuery.data]);

  /**Gets collection according to collection key */
  const getCollection = (
    collection: CollectionName
  ): Array<Collection> | undefined => {
    if (!taxonOptionsQuery.data || !optionsCollectionsQuery.data)
      return undefined;
    if (collection === "taxons") {
      return taxonOptionsQuery.data;
    }
    return optionsCollectionsQuery.data[collection];
  };

  const getCollectionItem = <T extends object>(
    collectionName: CollectionName,
    findFn: (item: any) => boolean
  ) => {
    const selectedCollection = getCollection(collectionName);
    if (!selectedCollection) return undefined;
    return selectedCollection.find(findFn) as T;
  };

  const getCollectionItems = <T extends object>(
    collectionName: CollectionName,
    filterFn: (item: any) => boolean
  ) => {
    const selectedCollection = getCollection(collectionName);
    if (!selectedCollection) return [] as T[];
    return selectedCollection.filter(filterFn) as Array<T>;
  };

  const getCollectionItemFieldById = <T extends object>(
    collection: CollectionName,
    field: keyof T,
    id: number | string | null,
    defaultValue: any = undefined
  ) => {
    if (!id) return defaultValue;
    const collectionItem: T | undefined = getCollectionItem(
      collection,
      (item) =>
        item.id === (typeof id === "string" ? parseInt(id) : id)
    );
    if (!collectionItem) return defaultValue;
    return collectionItem[field];
  };

  const getCollectionItemField = <T extends object>(
    collection: CollectionName,
    field: keyof T,
    findFn: (item: any) => boolean,
    defaultValue: any = undefined
  ) => {
    const collectionItem: T | undefined = getCollectionItem(
      collection,
      findFn
    );
    if (!collectionItem) return defaultValue;
    return collectionItem[field];
  };

  const getCollectionItemsFields = <T extends object>(
    collection: CollectionName,
    filterFn: (item: any) => boolean,
    field: keyof T
  ) => {
    const collectionItems: Array<T> = getCollectionItems(
      collection,
      filterFn
    );
    if (!collectionItems) return [] as any[];
    return collectionItems.map((item) => item[field]) as any;
  };

  return {
    getCollectionItem,
    getCollectionItems,
    getCollectionItemFieldById,
    getCollectionItemsFields,
    getCollectionItemField,
    isReady,
  };
};

export default useOptions;
